import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";

import { Title, Container, Button, Text, Paper } from "@mantine/core";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const paymentElementOptions = { layout: "tabs" };

  useEffect(
    () => {
      if (!stripe) {
        return;
      }

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    },
    [stripe]
  );

  const handleSubmit = async e => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    // redirect to payment, payment will redirect to the complete page
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://apply.tbcollege.com/payment",
      },
    });

    if (
      result.error.type === "card_error" ||
      result.error.type === "validation_error"
    ) {
      setMessage(result.error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <form id="payment-form" onSubmit={handleSubmit}>
      </form>
    </Container>
  );
};

export default CheckoutForm;
